<template>
  <div v-enter-viewport @enterViewport="animateIn">
    <HorizontalLine ref="hrRef" />
    <div class="reference">
      <div class="reference__visual-container">
        <ZoomOutImg
          :autoplay="false"
          class="reference__visual"
          :sets="[350, 640]"
          :picture="reference.picture"
        />
      </div>
      <div class="reference__content">
        <div
          class="reference__sectors"
          v-if="reference.sector && reference.sector.length > 0"
        >
          <LineSplitter
            v-for="(sector, i) in reference.sector"
            :key="i"
            class="reference__sector alt-heading"
            :content="sector.label"
            ref="sectorRef"
          />
        </div>
        <LineSplitter
          class="reference__title h2 mb-xl"
          :content="reference.title"
          ref="titleRef"
        />
        <div class="reference__details h4">
          <LineSplitter
            class="reference__details__item"
            v-if="reference.location"
            :content="reference.location"
            ref="locationRef"
          />
          <LineSplitter
            class="reference__details__item"
            v-if="reference.surface"
            :content="reference.surface"
            ref="surfaceRef"
          />
        </div>
        <div class="reference__details--secondary small">
          <LineSplitter
            v-if="
              reference.solution &&
              reference.solution.length > 0 &&
              reference.solution[0].title
            "
            class="reference__details__item"
            :content="reference.solution[0].title"
            ref="solutionRef"
          />
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <LineSplitter
            class="reference__details__item"
            v-for="(product, i) in reference.products"
            :key="i"
            :content="product.label"
            v-if="product.label"
            ref="productsRef"
          />
        </div>
      </div>

      <Arrow
        v-if="reference.hasDetail"
        class="reference__icon"
        ref="arrowRef"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .hr {
    @include liquid(margin-bottom, 30px, 35px);
  }
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import ZoomOutImg from './animation/ZoomOutImg.vue'
import LineSplitter from './animation/LineSplitter.vue'
import Arrow from '@/components/Arrow.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  components: { ZoomOutImg, LineSplitter, Arrow, HorizontalLine },
  props: {
    reference: {
      type: Object,
    },
  },
  setup() {
    const sectorRef = ref([])
    const titleRef = ref()
    const locationRef = ref()
    const surfaceRef = ref()
    const solutionRef = ref()
    const productsRef = ref()
    const arrowRef = ref()
    const hrRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const stagger = 0.1
        const duration = 1
        const offset = duration - stagger

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (hrRef.value) {
          TL.add(hrRef.value.getTween(), 'start')
        }

        if (sectorRef.value && sectorRef.value.length > 0) {
          sectorRef.value.forEach(vueComponent => {
            TL.add(vueComponent.getTween(), `-=${offset}`)
          })
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), `-=${offset}`)
        }

        if (locationRef.value) {
          TL.add(locationRef.value.getTween(), `-=${offset}`)
        }

        if (surfaceRef.value) {
          TL.add(surfaceRef.value.getTween(), `-=${offset}`)
        }

        if (solutionRef.value) {
          TL.add(solutionRef.value.getTween(), `-=${offset}`)
        }

        if (productsRef.value) {
          productsRef.value.forEach(el => {
            TL.add(el.getTween(), `-=${offset}`)
          })
        }

        if (arrowRef.value) {
          TL.add(arrowRef.value.getTween(), `-=${offset}`)
        }
      }
    }

    return {
      hrRef,
      animateIn,
      sectorRef,
      titleRef,
      locationRef,
      surfaceRef,
      solutionRef,
      productsRef,
      arrowRef,
    }
  },
})
</script>
