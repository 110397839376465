

































import { defineComponent, ref, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    name: {
      required: true,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    value: {
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const untouched = ref(true)

    const pristine = computed(() => untouched.value && !props.value)

    const reset = () => {
      ctx.emit('input', null)
      untouched.value = true
    }

    return {
      pristine,
      untouched,
      reset,
    }
  },
})
