








































































































































































































































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import WebP from '@/components/WebP.vue'
import BigCTA from '@/components/BigCTA.vue'
import FloatingLabelSelect from '@/components/FloatingLabelSelect.vue'
import ReferenceListItem from '@/components/ReferenceListItem.vue'

import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

const PER_PAGE = 8

export default defineComponent({
  components: {
    WebP,
    BigCTA,
    FloatingLabelSelect,
    LineSplitter,
    ReferenceListItem,
  },
  setup() {
    const { content } = useGetters(['content'])

    const currentIndex = ref(PER_PAGE)
    const referencesCount = computed(() => content.value.references.length)
    const remainingReferencesCount = computed(() =>
      Math.max(0, filteredReferences.value.length - currentIndex.value)
    )

    const sector = ref('')
    const solution = ref('')

    const filteredReferences = computed(() =>
      content.value.references.filter(reference => {
        if (
          sector.value &&
          reference.sector &&
          reference.sector.length > 0 &&
          reference.sector.find(s => s.value === sector.value) === undefined
        ) {
          return false
        }

        const hasOneOrMoreSolutions = Array.isArray(reference.solution)
        const matchesSelectedSolution =
          hasOneOrMoreSolutions &&
          reference.solution.find(s => s.title === solution.value) !== undefined
        if (Boolean(solution.value) && !matchesSelectedSolution) {
          return false
        }

        return true
      })
    )

    const paginatedReferences = computed(() =>
      filteredReferences.value.slice(0, currentIndex.value)
    )

    const loadMore = () => {
      if (remainingReferencesCount.value <= PER_PAGE) {
        currentIndex.value = filteredReferences.value.length
      } else {
        currentIndex.value += PER_PAGE
      }
    }

    const titleRef = ref()
    const subtitleRef = ref()
    const sectorSelectRef = ref()
    const solutionSelectRef = ref()

    onMounted(() => {
      if (motionOK()) {
        gsap.set([sectorSelectRef.value, solutionSelectRef.value], {
          yPercent: 100,
          opacity: 0,
        })
      }
    })

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }

        TL.to(
          [sectorSelectRef.value, solutionSelectRef.value],
          {
            yPercent: 0,
            opacity: 1,
            stagger: 0.1,
          },
          'start+=0.2'
        )
      }
    }

    return {
      content,
      sector,
      solution,
      currentIndex,
      referencesCount,
      remainingReferencesCount,
      filteredReferences,
      paginatedReferences,
      loadMore,
      titleRef,
      subtitleRef,
      animateIn,
      sectorSelectRef,
      solutionSelectRef,
    }
  },
})
