import { render, staticRenderFns } from "./ReferencesListing.vue?vue&type=template&id=45799450&scoped=true&"
import script from "./ReferencesListing.vue?vue&type=script&lang=ts&"
export * from "./ReferencesListing.vue?vue&type=script&lang=ts&"
import style0 from "./ReferencesListing.vue?vue&type=style&index=0&id=45799450&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45799450",
  null
  
)

export default component.exports